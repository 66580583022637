.App {
  text-align: center;
  height: 100vh;
  background-image: url('/wood_table_background.jpg');
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: black;
}

body{
  overscroll-behavior: none;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1.5vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



#headerGap{
  background-color:#f0d38f;
  height:10px;
}

#home{
  background-color:#ffdead;
  position:absolute;

}

.flip{
  transform: rotateX(-85deg);
  opacity: 0;
  z-index: 2;
  height: 100%;
}

.textBox{
  background-color:#fff9ef;
  width:310px;
  padding-left:20px;
  padding-right:30px;
  padding-top:10px;
  font-size:20px;
  font-weight:bold;
  vertical-align:center;
}









.active{
  position: absolute;
}
.hidden{
  display: none;
}
.icon{
  font-size:50px;
  margin-left:10px;
  color:green;
}
.icon:hover{
  color:blue;
}


#sendButton{
  background-color:transparent;
  border:1px solid #e6e600;
  float:right;
  margin-right:17px;
}
#sendButton:hover{
  background-color:blue;
  color:white;
}

#thanks{
  margin-top:50px;
  display:none;
  font-size:40px;
}

#aboutTextBox{
  width:60%;
  margin-top:50px;
}

#aboutText{
  margin-top:50px;
  font-size:24px;
  font-family:"Open Sans";
}